import { withPreview } from "gatsby-source-prismic";
import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
// import { RichText } from 'prismic-dom'
import { RichText } from "prismic-reactjs";
import { Layout, Listing, SliceZone, Title, SEO } from "@components";

import Wrapper from "@components/Wrapper";

import { Heading } from "@chakra-ui/core";

import website from "../../config/website";
import linkResolver from "@src/linkResolver";
import htmlSerializer from "@src/gatsby/htmlSerializer";

import { Global, css } from "@emotion/core";

// import { Link, RichText, Date } from 'prismic-reactjs'

const Hero = styled.header`
  background-color: ${(props) => props.theme.colors.greyLight};
  padding-top: 1rem;
  padding-bottom: 4rem;
`;

const PostWrapper = Wrapper;

const Page = ({ data: { prismicPage }, ...props }) => {
  const { data } = prismicPage;

  return (
    <Layout customSEO page>
      <Global
        styles={css`
          h3,
          h2 {
            margin-bottom: 0.8em;
            margin-top: 1.2em;
            font-size: 1.25em;
          }
        `}
      />
      <SEO
        title={`${data.page_title.text} | ${website.titleAlt}`}
        pathname={props.path}
        // desc={data.description}
        node={prismicPage}
        article
      />

      <Wrapper mb={"7rem"} p={4} narrow>
        <Heading as="h1" size="xl" mb={8} textAlign="center">
          {data.page_title.text}
        </Heading>
        {data.page_subtitle && (
          <Heading as="h4" size="sm" mb={4} lineHeight={1.5}>
            {data.page_subtitle}
          </Heading>
        )}
        <RichText
          render={data.page_content.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />
      </Wrapper>
      {/* <PostWrapper id={website.skipNavId}>
        <SliceZone allSlices={data.body} />
        <Title style={{ marginTop: '4rem' }}>Recent posts</Title>
        <Listing posts={posts.nodes} />
      </PostWrapper> */}
    </Layout>
  );
};

export default Page;

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        page_title {
          text
        }
        page_subtitle
        page_content {
          raw
          text
        }
        # description
        # body {
        #   ... on PrismicPostBodyText {
        #     slice_type
        #     id
        #     primary {
        #       text {
        #         html
        #       }
        #     }
        #   }
        #   ... on PrismicPostBodyCodeBlock {
        #     slice_type
        #     id
        #     primary {
        #       code_block {
        #         html
        #       }
        #     }
        #   }
        #   ... on PrismicPostBodyQuote {
        #     slice_type
        #     id
        #     primary {
        #       quote {
        #         html
        #         text
        #       }
        #     }
        #   }
        #   ... on PrismicPostBodyImage {
        #     slice_type
        #     id
        #     primary {
        #       image {
        #         localFile {
        #           childImageSharp {
        #             fluid(maxWidth: 1200, quality: 90) {
        #               ...GatsbyImageSharpFluid_withWebp
        #             }
        #           }
        #         }
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;
