module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "KBT-terapi i Stockholm - Kerstin Sandqvist", // Navigation and Site Title
  titleAlt: "KBT-terapi i Stockholm - Kerstin Sandqvist", // Title for JSONLD
  description:
    "Upplever du oro, stress eller andra utmaningar? Tillsammans hjälps vi åt med det som känns svårt. Boka ett första samtal där vi kan prata om dina behov.",
  headline: "KBT-terapi i Stockholm - Kerstin Sandqvist", // Headline for schema.org JSONLD
  url: "https://kerstinsandqvist.se", // Domain of your site. No trailing slash!
  siteLanguage: "sv", // Language Tag on <html> element
  logo: "/splash.png", // Used for SEO
  ogLanguage: "sv_SE", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/images/favicon.png", // Used for manifest favicon generation
  shortName: "Prismic", // shortname for manifest. MUST be shorter than 12 characters
  author: "Robin", // Author for schemaORGJSONLD
  themeColor: "#3D63AE",
  backgroundColor: "#EBEDF2",
  googleAnalyticsID: "UA-47519312-7",
  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
};
